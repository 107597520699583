<ng-container *ngIf="isError; else data">
    <div class="stats-error-container tab-content bg-white p-3 has-nomenclature position-relative">
        <div id="tab1-content" role="tabpanel" aria-labelledby="tab1" class="tab-pane active">
            <div class="table-sport mx-n3 mx-sm-0">
                <div class="table-sport__content ng-star-inserted">
                    <div id="tab2-content" role="tabpanel" aria-labelledby="tab2" class="tab-pane ng-star-inserted">
                        <div data-cy="app-match-no-data" class="text-center pt-7 app-match-no-data">
                            <svg class="icon icon--64 mb-4"><use xlink:href="#icon-graph"></use></svg>
                            <h4 class="font-weight-bold">{{ 'web.statistics_not_exists' | translate }}</h4>
                            <p class="mb-6">{{ 'web.statistics_error_info' | translate }}</p>
                        </div>
                    </div>

                    <hr class="ng-star-inserted" />
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #data>
    <router-outlet></router-outlet>
</ng-template>
<app-lang class="d-none" [flags]="false"></app-lang>

<!-- <div class="loader-active widget-loader" *ngIf="!loaded"></div> -->
<app-page-loader *ngIf="!loaded" class="app-tracker-loader"></app-page-loader>
