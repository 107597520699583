<div *ngIf="loaded">
    <ng-container *ngIf="!noSufficientData && hasTimelineData; else nodata">
        <ngx-charts-polar-chart
            [view]="view"
            [scheme]="colorScheme"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="legendTitle"
            [legendPosition]="legendPosition"
            [showGridLines]="showGridLines"
            [autoScale]="autoScale"
            [results]="multi"
        >
            <ng-template #tooltipTemplate let-model="model"> {{ model.value }}{{ mark }} </ng-template>
        </ngx-charts-polar-chart>
    </ng-container>
    <ng-template #nodata>
        <div id="tab2-content" role="tabpanel" aria-labelledby="tab2" class="tab-pane ng-star-inserted">
            <div data-cy="app-match-no-data" class="text-center pt-7 app-match-no-data">
                <svg class="icon icon--64 mb-4"><use xlink:href="#icon-graph"></use></svg>
                <h4 class="font-weight-bold">{{ 'web.no_standings_stats' | translate }}</h4>
                <p class="mb-6">{{ 'web.no_standings_stats_info' | translate }}</p>
            </div>
        </div>
    </ng-template>
</div>
