/* eslint-disable max-len */
/**
 *  Statistics Participant score per minute in match
 *  @module Statistics
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2024 livescore
 */


import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';


import { StatsMixin } from '@statistics/services/mixins/statistics.mixin';

import { BaseComponent } from '@statistics/pages/base.component';

import { StatisticsService } from '@statistics/services/statistics.service';

import { LangService, MatchService } from '@statistics/export';
import { map } from 'rxjs/operators';


import SPORT_CONFIG from '@config/sport.config';
import { TranslateService } from '@ngx-translate/core';
import { ParticipantService } from '@services/participant.service';

import { MatchType } from '@interfaces/league-table.interface';

import { each } from 'lodash-es';

import { LastForm, FormData } from '@/modules/widgets/interfaces/widget.interface';


@Component({
    selector: 'statistics-participant-season-standing-position',
    templateUrl: './participant-periond-minutes-score.component.html',
    styleUrls: ['./style.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ParticipantPeriodScoreComponent extends StatsMixin(BaseComponent) implements OnInit, OnDestroy {
    private subscriber: Subscription;

    private matchId: number;

    public data: Record<string, any>;

    view: any[] = [200, 210];

    public noSufficientData = false;


    // options
    legend: boolean = false;
    showLabels: boolean = true;
    animations: boolean = true;
    xAxis: boolean = true;
    yAxis: boolean = false;
    showYAxisLabel: boolean = false;
    showXAxisLabel: boolean = true;
    showGridLines = true;
    autoScale = false;
    xAxisLabel: string = 'Year';
    yAxisLabel: string = 'Population';
    legendTitle: string = 'Minutes';
    legendPosition: string = 'right';

    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
    };


    multi = [
        {
            name: 'Germany',
            series: [
                {
                    name: '90\'',
                    value: 0,
                },
                {
                    name: '15\'',
                    value: 0,
                },
                {
                    name: '30\'',
                    value: 0,
                },
                {
                    name: '45\'',
                    value: 0,
                },
                {
                    name: '60\'',
                    value: 0,
                },
                {
                    name: '75\'',
                    value: 0,
                },
            ],
        },


    ];

    public times: [number, number][] = [[0, 15], [16, 30], [31, 45], [45, 60], [61, 75], [76, 90]];
    public hasTimelineData = false;
    public total: Record<string, number> = {};
    public totalConcated: Record<string, number> = {};

    #participant: number;

    #sportName: string;

    #type: 'total' | 'average' | 'percentage' = 'total';

    #score: 'scored' | 'concated' = 'scored';

    #width: number;
    #height: number;

    mark = '';

    public constructor(
        private router: ActivatedRoute,
        private lang: LangService,
        protected stats: StatisticsService,
        private match: MatchService,
        private translate: TranslateService,
        public service:ParticipantService,

    ) {
        super(stats);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.router.queryParamMap.subscribe((params: ParamMap) => {
            this.router.data.subscribe(() => {
                this.translate.get('web.rankings').subscribe(() => {
                    this.xAxisLabel = this.translate.instant(`web.match_minute_score_${this.#score}_${this.#type}`);
                });
            });

            if (params.has('p')) {
                this.#participant = +params.get('p')!;
            }

            if (params.has('sportName')) {
                this.#sportName = params.get('sportName')!;
            }

            if (params.has('type')) {
                this.#type = params.get('type')! as 'total' | 'average' | 'percentage';

                if (this.#type === 'percentage') {
                    this.mark = '%';
                }
            }

            if (params.has('score')) {
                this.#score = params.get('score')! as 'scored' | 'concated';

                if (this.#score === 'concated') {
                    this.colorScheme.domain[0] = '#a8385d';
                }
            }

            if (params.has('width')) {
                this.#width = +params.get('width')!;

                if (this.#width) {
                    this.view[0] = this.#width;
                }
            }

            if (params.has('height')) {
                this.#height = +params.get('height')!;

                if (this.#height) {
                    this.view[1] = this.#height;
                }
            }


            if (!this.hasScoredGoalsStats) {
                this.stats.loadFailed();
            } else {
                this.load();
            }


            document.querySelector('body')!.classList.add('transparent-body');
        });
    }

    private normalizeGraph(): void {
        const p = document.querySelectorAll('[ngx-charts-y-axis-ticks] text');
        p.forEach((item) => {
            (item as any).textContent = item.textContent?.trim().replace('-', '').replace('.0', '');
        });
    }


    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
    }

    get hasScoredGoalsStats(): boolean {
        return SPORT_CONFIG.statistics.scoredGoals.includes(this.#sportName);
    }

    protected isHome(name: string): boolean {
        return this.data.name === name;
    }

    public processData(): void {
        let numMatches = 0;
        let numGoals = 0;
        let numGoalsConcated = 0;
        this.data.form.forEach(((f: LastForm) => {
            const isHome = this.isHome(f.home_team);


            if (!f.timeline || f.timeline.length === 0) { return; }
            numMatches += 1;

            f.timeline.forEach((val) => {
                if (val.type === SPORT_CONFIG.timeline.eventTypes.scoreChange) {
                    this.hasTimelineData = true;
                    const findPeriod = this.times.find(
                        (t: [number, number]) => t[0] <= +val.match_time && t[1] >= +val.match_time);

                    if (!findPeriod || !val.match_time) {
                        return;
                    }
                    const [start, end] = findPeriod;
                    const periodString = `${start}-${end}`;


                    if ((val.competitor === 'home' && isHome) || (val.competitor === 'away' && !isHome)) {
                        if (periodString in this.total === false) {
                            this.total = { ...this.total, [periodString]: 0 };
                        }

                        this.total[periodString]! += 1;
                        numGoals += 1;
                    } else {
                        if (periodString in this.totalConcated === false) {
                            this.totalConcated = { ...this.totalConcated, [periodString]: 0 };
                        }

                        this.totalConcated[periodString]! += 1;
                        numGoalsConcated += 1;
                    }
                }
            });
        }));

        if (this.#type === 'average' && this.total) {
            each(this.total, (val, key) => {
                this.total[key] = +(this.total[key]! / numMatches).toFixed(2);
            });
            each(this.totalConcated, (val, key) => {
                this.totalConcated[key] = +(this.totalConcated[key]! / numMatches).toFixed(2);
            });
        }

        if (this.#type === 'percentage' && this.total) {
            each(this.total, (val, key) => {
                this.total[key] = Math.floor(+(this.total[key]! / numGoals) * 100);
            });
            each(this.totalConcated, (val, key) => {
                this.totalConcated[key] = Math.floor(+(this.totalConcated[key]! / numGoalsConcated) * 100);
            });
        }


        if (this.#score === 'scored') {
            this.multi[0]!.series[0]!.value = this.total['76-90'] ?? 0;
            this.multi[0]!.series[1]!.value = this.total['0-15'] ?? 0;
            this.multi[0]!.series[2]!.value = this.total['16-30'] ?? 0;
            this.multi[0]!.series[3]!.value = this.total['31-45'] ?? 0;
            this.multi[0]!.series[4]!.value = this.total['45-60'] ?? 0;
            this.multi[0]!.series[5]!.value = this.total['61-75'] ?? 0;
        } else if (this.#score === 'concated') {
            this.multi[0]!.series[0]!.value = this.totalConcated['76-90'] ?? 0;
            this.multi[0]!.series[1]!.value = this.totalConcated['0-15'] ?? 0;
            this.multi[0]!.series[2]!.value = this.totalConcated['16-30'] ?? 0;
            this.multi[0]!.series[3]!.value = this.totalConcated['31-45'] ?? 0;
            this.multi[0]!.series[4]!.value = this.totalConcated['45-60'] ?? 0;
            this.multi[0]!.series[5]!.value = this.totalConcated['61-75'] ?? 0;
        }
    }

    private load(): void {
        this.translate.get('web.home').subscribe(() => {
            this.service.getForm(this.#participant, { limit: 30, extraStats: 1 })
                .pipe(


                    map((v: FormData) => {
                        v.form = v.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                        return v;
                    })).subscribe(
                    (data) => {
                        this.data = data;


                        this.processData();
                        this.finished();

                        setTimeout(() => {
                            this.normalizeGraph();
                        });
                    },
                    () => {
                        this.stats.loadFailed();
                    },
                    () => {
                        this.loaded = true;
                    },
                );
        });
    }
}
