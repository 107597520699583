/**
 *  Statistics Parent page component
 *  @module Statistics
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2023 livescore
 */


import { Component, OnInit, ViewEncapsulation } from '@angular/core';


import { StatsMixin } from '@statistics/services/mixins/statistics.mixin';

import { BaseComponent } from '@statistics/pages/base.component';

import { StatisticsService } from '@statistics/services/statistics.service';
import { TranslationService } from '@statistics/export';


@Component({
    selector: 'statistics-main',
    templateUrl: './statistics.component.html',
    styleUrls: ['./style.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StatisticsComponent extends StatsMixin(BaseComponent) implements OnInit {
    public constructor(
        protected stats: StatisticsService,

    ) {
        super(stats);
    }

    public ngOnInit(): void {
        TranslationService.isLoaded = false;

        this.stats.widgetLoaderFailed.subscribe((val) => {
            if (val) {
                this.error();
            }
        });
        this.stats.widgetLoader.subscribe((val) => {
            if (val) {
                this.loaded = true;
            }
        });
    }
}
