import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import CONFIG from '@widgets/config/widget.config';
import { ParticipantResolverService } from '@statistics/services/participant-resolver.service';
import { ParticipantSeasonStandingPositionComponent }
    from '@statistics/pages/participant-season-standing-position/participant-season-standing-position.component';
import { StatisticsComponent } from '@statistics/pages/statistics.component';
import { ParticipantPeriodScoreComponent }
    from '@statistics/pages/participant-periond-minutes-score/participant-periond-minutes-score.component';


const routes: Routes = [

    {
        component: StatisticsComponent,
        path: '',
        children: [
            {
                path: 'participant-season-standings-graph/:matchId/:type',
                component: ParticipantSeasonStandingPositionComponent,
                data: {
                    restrictSports: CONFIG.underOver3,
                    num: 20,
                },
                resolve: {
                    br: ParticipantResolverService,
                },
            },
            {
                path: 'participant-period-score',
                component: ParticipantPeriodScoreComponent,
            },


        ],
    },
    { path: '', redirectTo: 'participant-season-standings-graph', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class StatisticsRoutingModule {}
