<div class="stats-participant-season-standings" *ngIf="loaded">
    <ng-container *ngIf="!noSufficientData; else nodata">
        <ngx-charts-line-chart
            [view]="view"
            [scheme]="colorScheme"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [roundDomains]="roundDomains"
            [yAxisTicks]="yAxisTicks"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [referenceLines]="referenceLines"
            [yAxisTickFormatting]="yAxisTickFormatting"
            [showRefLines]="showRefLines"
            [yAxisLabel]="yAxisLabel"
            [timeline]="timeline"
            [results]="multi"
            [yScaleMin]="minYAxis"
            [yScaleMax]="maxYAxis"
        >
            <ng-template #tooltipTemplate let-model="model">
                {{ abs(model.value) }}
            </ng-template>

            <ng-template #seriesTooltipTemplate let-model="model">
                {{ abs(model.value) }}
            </ng-template>
        </ngx-charts-line-chart>
    </ng-container>
    <ng-template #nodata>
        <div id="tab2-content" role="tabpanel" aria-labelledby="tab2" class="tab-pane ng-star-inserted">
            <div data-cy="app-match-no-data" class="text-center pt-7 app-match-no-data">
                <svg class="icon icon--64 mb-4"><use xlink:href="#icon-graph"></use></svg>
                <h4 class="font-weight-bold">{{ 'web.no_standings_stats' | translate }}</h4>
                <p class="mb-6">{{ 'web.no_standings_stats_info' | translate }}</p>
            </div>
        </div>
    </ng-template>
</div>
