/**
 *  Statistics main config
 *  @module Statistics
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2023 livescore
 */
export default {
    participantStandings: {
        availSports: ['soccer', 'ice-hockey', 'handball', 'basketball',
            'volleyball', 'futsal', 'baseball', 'rugby', 'futsal', 'american-football'],
    },
};
