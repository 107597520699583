import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { SharedModule } from 'src/app/shared/shared.module';

import { ComponentModule } from '@components/component.module';

import { ParticipantSeasonStandingPositionComponent }
    from '@statistics/pages/participant-season-standing-position/participant-season-standing-position.component';

import { StatisticsComponent } from '@statistics/pages/statistics.component';

import { ParticipantPeriodScoreComponent }
    from '@statistics/pages/participant-periond-minutes-score/participant-periond-minutes-score.component';

import { StatisticsRoutingModule } from './statistics-routing.module';


@NgModule({
    declarations: [
        StatisticsComponent,
        ParticipantSeasonStandingPositionComponent,
        ParticipantPeriodScoreComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        StatisticsRoutingModule,
        ComponentModule,
        NgxChartsModule,
    ],
    providers: [],
})
export class StatisticsModule {}
