import { EventEmitter, Injectable } from '@angular/core';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '@widgets/index';
import URL_CONFIG from '@statistics/config/url.config';

import { ParticipantStandings } from '@statistics/interfaces/participant-standings.interface';

import { MyMatchesService } from '@/services/my-matches.service';

@Injectable({
    providedIn: 'root',
})
export class StatisticsService {
    public widgetLoader: EventEmitter<boolean> = new EventEmitter();
    public widgetLoaderFailed: EventEmitter<boolean> = new EventEmitter();

    public constructor(private http: HttpClient, private common: CommonService, public matches: MyMatchesService) {}

    /**
     * Convert source id to our match id
     */
    public sourceIdToId(idValFinal: number, source: string | null): Observable<number[]> {
        if (source === null) {
            return this.matches.brToId(idValFinal.toString());
        }
        return this.matches.sourceIdToId(idValFinal.toString(), source);
    }

    public getParticipantStandings(seasonId: number | undefined, subtournamentId: number,
        participantId: number, codeName: string): Observable<ParticipantStandings[]> {
        const url = `${URL_CONFIG.api.participantStandings(seasonId, subtournamentId, participantId)}?lang=${codeName}`;

        const options = {};
        return this.http.get<ParticipantStandings[]>(url, options).pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    public loadFailed(): void {
        this.widgetLoaderFailed.emit(true);
    }
}
