/* eslint-disable max-len */
/**
 *  Statistics Participant season graph
 *  @module Statistics
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2023 livescore
 */


import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';

import CONFIG from '@statistics/config/stats.config';

import { StatsMixin } from '@statistics/services/mixins/statistics.mixin';

import { BaseComponent } from '@statistics/pages/base.component';

import { StatisticsService } from '@statistics/services/statistics.service';

import { LangService, MatchData, MatchService } from '@statistics/export';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { ParticipantStandings } from '@statistics/interfaces/participant-standings.interface';

import { uniq, map } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

declare const moment: any;

@Component({
    selector: 'statistics-participant-season-standing-position',
    templateUrl: './participant-season-standing-position.component.html',
    styleUrls: ['./style.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ParticipantSeasonStandingPositionComponent extends StatsMixin(BaseComponent) implements OnInit, OnDestroy {
    private subscriber: Subscription;

    private matchId: number;

    public data: Record<string, any>;

    view: any = undefined;

    public noSufficientData = false;

    // options
    legend: boolean = true;
    showLabels: boolean = true;
    animations: boolean = true;
    xAxis: boolean = true;
    yAxis: boolean = true;
    showYAxisLabel: boolean = true;
    showXAxisLabel: boolean = true;
    xAxisLabel: string = 'Weeks';
    yAxisLabel: string = 'Rankings';
    timeline: boolean = true;
    roundDomains: boolean = false;
    maxYAxis = 1;
    minYAxis = 25;

    yAxisTickFormatting = (value: number): number => value * -1;

    type: 'home' | 'away' = 'home';

    public referenceLines: {name: string, value: number}[] = [];

    public yAxisTicks: number[] = [];

    showRefLines = true;

    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#a8385d', '#CFC0BB', '#7aa3e5', '#aae3f5'],
    };

    public multi: Record<string, any> = [


    ];


    public constructor(
        private router: ActivatedRoute,
        private lang: LangService,
        protected stats: StatisticsService,
        private match: MatchService,
        private translate: TranslateService,

    ) {
        super(stats);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.router.paramMap.subscribe((params) => {
            this.type = params.has('type') && params.get('type') === 'away'
                ? params.get('type') as 'home' | 'away' : 'home';

            this.router.data.subscribe((sub) => {
                // const { num = null } = sub;

                if (sub.br && sub.br.length > 0) {
                    [this.matchId] = sub.br;
                    this.load();
                } else {
                    this.stats.loadFailed();
                }

                this.translate.get('web.rankings').subscribe(() => {
                    this.xAxisLabel = this.translate.instant('web.weeks');
                    this.yAxisLabel = this.translate.instant('web.rankings');
                });
            });
        });
    }

    private normalizeGraph(): void {
        const p = document.querySelectorAll('[ngx-charts-y-axis-ticks] text');
        p.forEach((item) => {
            (item as any).textContent = item.textContent?.trim().replace('-', '').replace('.0', '');
        });
    }

    public abs(val: number): number {
        return Math.abs(val);
    }


    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
    }


    private load(): void {
        this.translate.get('web.home').subscribe(() => {
            const iso: string | null = this.lang.getLangSnapshot();


            this.subscriber = this.match
                .getMatchNew(this.matchId, iso as string, true)
                .pipe(
                    filter((match: MatchData) => CONFIG.participantStandings.availSports.includes(match.sport_code_name)),
                    mergeMap(
                        (match: MatchData): Observable<ParticipantStandings[]> => {
                            const key = this.type === 'home' ? 0 : 1;
                            const { id: participantId } = match.participants[key] as any;
                            return this.stats.getParticipantStandings(match.season_id, match.sub_tournament_id,
                                participantId, iso);
                        },
                    ),


                    catchError(
                        (e): Observable<any> => {
                            this.error(e);
                            return throwError(e);
                        },
                    ),
                )

                .subscribe(
                    (data) => {
                        this.data = data;
                        let maxRank = 1;


                        if (data[0]?.history) {
                            const home: Record<string, any> = { name: this.translate.instant('web.home'), series: [] };
                            const away: Record<string, any> = { name: this.translate.instant('web.away'), series: [] };
                            const total: Record<string, any> = { name: this.translate.instant('web.total'), series: [] };
                            data[0]?.history.forEach((history: Record<string, any>) => {
                                const date : any = moment(history.date);
                                total.series.push({ name: `${date.format('W')}`, value: 1 * history.positionTotal });

                                if (history.positionHome) {
                                    home.series.push({ name: `${date.format('W')}`, value: 1 * history.positionHome });
                                }

                                if (history.positionAway) {
                                    away.series.push({ name: `${date.format('W')}`, value: 1 * history.positionAway });
                                }

                                maxRank = Math.max(history.positionTotal, history.positionHome, history.positionAway);
                            });

                            this.multi = [total, home, away];
                        }


                        if (data[0]?.promotions && data[0]?.promotions.length > 0) {
                            let positionUp = true;
                            const promotionMapped = data[0].promotions.map((val: Record<string, any>, key: number) => {
                                if (key === 0 && val.rank !== 1) {
                                    positionUp = false;
                                }
                                const next = data[0].promotions[key + 1];
                                const retVal = { ...val, positionUp };
                                if (next) {
                                    if ((+val.rank + 1) !== next.rank) {
                                        positionUp = false;
                                    }
                                }

                                return retVal;
                            });

                            const promotionUnique = uniq(map(promotionMapped, 'promotion'));

                            promotionUnique.forEach((name: string) => {
                                const first = promotionMapped.find(
                                    (promotion: Record<string, any>) => promotion.promotion === name);
                                const last = promotionMapped.findLast(
                                    (promotion: Record<string, any>) => promotion.promotion === name);

                                if (last) {
                                    maxRank = Math.max(maxRank, last.rank);
                                }

                                if (first && !first.positionUp) {
                                    this.referenceLines.push({ name: `${first.promotion}`, value: 1 * first.rank });
                                }

                                if (last && first.positionUp) {
                                    this.referenceLines.push({ name: `${last.promotion}`, value: 1 * last.rank });
                                }

                                // TODO: old calculation delete if test ok ver 3.1.6
                                // if (last && last.rank <= latestPosition) {
                                //     this.referenceLines.push({ name: last.promotion, value: 1 * last.rank });
                                // } else if (first && first.rank >= latestPosition) {
                                //     this.referenceLines.push({ name: first.promotion, value: 1 * first.rank });
                                // }
                            });
                        }

                        for (let x = 1; x <= maxRank; x += 1) {
                            this.yAxisTicks.push(1 * x);
                        }

                        this.minYAxis = maxRank;
                        this.finished();

                        const count = this.multi?.[0]?.series?.length ?? 0;

                        if (count <= 1) {
                            this.noSufficientData = true;
                        }

                        setTimeout(() => {
                            this.normalizeGraph();
                        });
                    },
                    () => {
                        this.stats.loadFailed();
                    },
                    () => {
                        this.loaded = true;
                    },
                );
        });
    }
}
