/**
 *  Base class for components
 *  @module Statistics
 *  @author Livescore
 *  @copyright 2023 livescore
 */

import * as $ from 'jquery';
import { isUndefined } from 'lodash-es';
import APP_CONFIG from '@config/app.config';
import { Directive } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, of } from 'rxjs';

import { StatisticsService } from '@statistics/services/statistics.service';

@Directive()
export class BaseComponent {
    protected auth: boolean = false;

    public loaded: boolean = false;

    public loadedError: boolean = false;

    public noTitle: boolean = false;

    public loadedErrorReason: string = 'error_match';


    public num: number;

    public constructor(protected stats: StatisticsService) {
        $('body').addClass('widget-body');
    }

    public ngOnInit(): void {
        $('#loader').hide();
        $('body').removeClass('loader-active');
    }

    public trackByFn(index: number, item: Record<string, any>): number | null {
        if (isUndefined(item)) {
            return null;
        }
        return item.id;
    }

    public trackByIndex(index: number): number {
        return index;
    }

    public get isError(): boolean {
        return this.loadedError;
    }

    protected setTitle(router: ActivatedRoute): void {
        router.paramMap.subscribe((param: ParamMap) => {
            if (param.has('notitle')) {
                this.noTitle = true;
            }
        });
    }

    protected checkEmpty<T>(): (data: T) => Observable<T | string> {
        return (data: T): Observable<T> =>
            // if (false) {
            //     return throwError('empty-data');
            // }
            // eslint-disable-next-line implicit-arrow-linebreak
            of(data);
    }

    /**
     * Error load data
     */
    protected error(reason: string = 'error_match'): void {
        this.loaded = true;
        this.loadedError = true;
        this.loadedErrorReason = reason;
    }

    /**
     * Successload data
     */
    protected finished(): void {
        this.loaded = true;
        this.loadedError = false;

        this.stats.widgetLoader.emit(true);
    }

    /**
     * @listens BaseComponent#imageError
     * Listen to image error and load defautl image
     */
    public imageError(e: Record<string, any>, type = 'team'): void {
        const img = type === 'avatar' ? APP_CONFIG.defaultAvatar : APP_CONFIG.defaultLogo;
        e.target.src = img;
    }
}
